<template>
  <v-container class="mb-10">
    <h1>Wallet</h1>
    <p class="text-subtitle-1 font-weight-bold">
      <!--      Got questions? Call or Whatsapp {{ siteSettings.phone }}-->
    </p>
    <v-overlay opacity="0.8" :value="gettingWallet">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="12">
        <v-card elevation="3" color="primary" rounded="lg" class="pa-2">
          <v-card-text class="white--text">
            <p class="overline white--text opacity-80 mb-0">
              Your {{ getCurrencyCode(user.country) }} Wallet
            </p>
            <span class="opacity-85">Available Balance</span>
            <div class="flex items-center">
              <h4 v-if="showAmount" class="text-h4">
                {{ userCurrency }} {{ numberWithCommas(wallet.balance) }}
              </h4>
              <h4 v-else class="text-h4">*********</h4>
              <span
                class="ml-5 cursor-auto"
                @click="
                  showAmount = !showAmount
                  localStorage.setItem('showAmount', showAmount)
                "
              >
                <v-icon v-if="showAmount" size="32" color="white"
                  >mdi-eye</v-icon
                >
                <v-icon v-else size="32" color="white">mdi-eye-off</v-icon>
              </span>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="d-block d-sm-flex">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-btn
              depressed
              to="/user/wallet/withdraw"
              class="mt-2 mx-2 text-center"
            >
              Withdraw
            </v-btn>
            <!--            <v-btn-->
            <!--              depressed-->
            <!--              to="/user/wallet/fund"-->
            <!--              class="mt-2 mx-2 text-center"-->
            <!--              v-if="userCountry === COUNTRIES.NG"-->
            <!--            >-->
            <!--              Fund-->
            <!--            </v-btn>-->
            <v-btn
              depressed
              to="/user/wallet/transfer"
              class="mt-2 mx-2 text-center"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import walletApi from '../../../api/users/wallet'
import { mapState } from 'vuex'
import { numberWithCommas } from '../../../utils/helpers'
import { COUNTRIES, getCurrencyCode } from '../../../utils/countryHelpers'

export default {
  data: () => ({
    COUNTRIES,
    getCurrencyCode,
    numberWithCommas,
    cashbackDialog: false,
    convertDialog: false,
    banks: 0,
    loadingConvert: false,
    gettingWallet: false,
    amount: '',
    loadingConvertCashback: false,
    cashbackAmount: '',
    showAmount: false
  }),
  components: {},
  computed: {
    ...mapState({
      wallet: (state) => state.user.wallet,

      user: (state) => state.user.user,
      siteSettings: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    })
  },
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.$store.dispatch('user/getCountryCurrency')
    this.getWalletData()
  },
  mounted() {
    const showAmount = localStorage.getItem('showAmount')
    if (showAmount) {
      this.showAmount = showAmount
    } else {
      this.showAmount = false
      localStorage.setItem('showAmount', false)
    }
  },
  methods: {
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    },

    async getWalletData() {
      this.gettingWallet = true
      const res = await walletApi.data().getWallet()
      this.$store.commit('user/setWalletData', res.data.data)
      this.gettingWallet = false
    }
  }
}
</script>

<style></style>
